import { defineStore } from "pinia";

export const useCommonStore = defineStore("commonStore", {
  state: () => ({
    ROUTE_MAIN: {
      HOME: "HOME",
      MINTING: "MINTING",
      MY_PAGE: "MY_PAGE",
    },
    ROUTE_MINTING: [],
    CURRENT_ROUTE: {
      MAIN: "HOME",
      MINTING: "MINTING_FIRST",
    },

    minting_info_data: null,
    nft_card_data: null,
  }),
  getters: {},
  actions: {
    // 진행 민팅 갯수에 따라 라우팅 설정
    setInitMintingRoute(minting_route_list) {
      this.ROUTE_MINTING = minting_route_list;
    },
    // 메인 라우팅 설정
    setMainRoute(route) {
      this.CURRENT_ROUTE.MAIN = route;
    },
    // 민팅 라우팅 설정
    setMintingRoute(route) {
      this.CURRENT_ROUTE.MINTING = route;
    },
    // 민팅 정보 데이터 설정
    setMintingInfoData(minting) {
      this.minting_info_data = minting;
    },
    // NFT 카드 데이터 설정
    setNftCardData(nft) {
      this.nft_card_data = nft;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "commonStore",
        storage: localStorage,
      },
    ],
  },
});
