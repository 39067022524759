// ============================== Wallet Connect 기본 설정 =================================
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi";
import { bsc } from "viem/chains";
import { reconnect } from "@wagmi/core";

const projectId = "eb372be0e147fb43b2ae61793fd8a3d3";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [bsc];
const chain_id = bsc.id;

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

reconnect(config);

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  themeMode: "dark",
});

const WALLET_SETTING = {
  chain_id,
  config,
};

export { WALLET_SETTING };

// ============================== ABI 기본 설정 =================================
import TOKEN_ABI from "@/assets/abi/token.json";
import USDT_ABI from "@/assets/abi/usdt.json";
import TIMELOCK_ABI from "@/assets/abi/timelock.json";
import PRESALE_ABI from "@/assets/abi/presale.json";
import STAKING_ABI from "@/assets/abi/staking.json";
import MINTING_ABI from "@/assets/abi/minting.json";

const WALLET_CONTRACT = {
  TOKEN: {
    ABI: TOKEN_ABI["abi"],
    ADDRESS: TOKEN_ABI["contractAddress"],
  },
  USDT: {
    ABI: USDT_ABI["abi"],
    ADDRESS: USDT_ABI["contractAddress"],
  },
  TIMELOCK: {
    ABI: TIMELOCK_ABI["abi"],
    ADDRESS: TIMELOCK_ABI["contractAddress"],
  },
  PRESALE: {
    ABI: PRESALE_ABI["abi"],
    ADDRESS: PRESALE_ABI["contractAddress"],
  },
  STAKING: {
    ABI: STAKING_ABI["abi"],
    ADDRESS: STAKING_ABI["contractAddress"],
  },
  MINTING_FIRST: {
    ABI: MINTING_ABI["abi"],
    ADDRESS: MINTING_ABI["contractAddress_first"],
  },
  MINTING_SECOND: {
    ABI: MINTING_ABI["abi"],
    ADDRESS: MINTING_ABI["contractAddress_second"],
  },
  MINTING_THIRD: {
    ABI: MINTING_ABI["abi"],
    ADDRESS: MINTING_ABI["contractAddress_third"],
  },
  MINTING_FOURTH: {
    ABI: MINTING_ABI["abi"],
    ADDRESS: MINTING_ABI["contractAddress_fourth"],
  },
};

export { WALLET_CONTRACT };

// ============================== ABI API =================================
const WALLET_API = {
  TYPE: {
    TOKEN: "TOKEN",
    USDT: "USDT",
    TIMELOCK: "TIMELOCK",
    PRESALE: "PRESALE",
    STAKING: "STAKING",
    MINTING_FIRST: "MINTING_FIRST",
    MINTING_SECOND: "MINTING_SECOND",
    MINTING_THIRD: "MINTING_THIRD",
    MINTING_FOURTH: "MINTING_FOURTH",
  },
  TOKEN: {
    WRITE_APPROVE: "approve",
    GET_BALANCE: "balanceOf",
    GET_ALLOWANCE: "allowance",
  },
  USDT: {},
  TIMELOCK: {},
  PRESALE: {},
  STAKING: {},
  MINTING: {
    WRITE_SET_TRANSFER_OWNERSHIP: "transferOwnership",
    WRITE_START_MINTING: "startMinting",
    WRITE_SET_BASE_URI: "setBaseURI",
    WRITE_MINTING: "mint",
    WRITE_MINTING_TO_SINGLE: "mintToSingleAddress",
    WRITE_SET_START_BLOCK: "setStartBlock",
    WRITE_SET_END_BLOCK: "setEndBlock",
    WRITE_SET_PRICE: "setPrice",
    WRITE_SET_INTERVAL: "setInterval",
    WRITE_SET_MAX_PURCHASE_LIMIT: "setMaxPurchaseLimit",
    WRITE_SET_MAX_NFT_PER_WALLET: "setMaxNFTPerWallet",
    WRITE_SET_TARGET_ID: "updateTargetId",
    WRITE_WITHDRAW_TOKEN: "withdrawToken",

    GET_ADMIN: "owner",
    GET_BALANCE: "balanceOf",
    GET_TOKEN_OWNER_BY_INDEX: "tokenOfOwnerByIndex",
    GET_CONTRACT_STATE: "getContractState",
    GET_START_BLOCK: "startBlock",
    GET_END_BLOCK: "endBlock",
    GET_PER_TRANSACTION: "maxPurchaseLimit",
    GET_PER_WALLET: "maxNFTPerWallet",
    GET_TOTAL_SUPPLY: "totalSupply",
    GET_LATEST_ID: "latestId",
    GET_TARGET_ID: "targetId",
    GET_PRICE: "price",
    GET_INTERVAL: "interval",
    GET_CURRENT_BLOCK_NUMBER: "getCurrentBlockNumber",
    GET_PREVIOUS_TARGET_MINTING: "previousTargetId",
  },
};

export { WALLET_API };
