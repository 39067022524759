import { defineStore } from "pinia";

export const useModalStore = defineStore("modalStore", {
  state: () => ({
    loading_modal: {
      state: false,
    },
    alert_modal: {
      state: false,
      type: "",
      title: "",
      message: "",
    },
    minting_info_modal: {
      state: false,
      img: "",
      name: "",
      description: "",
    },
    nft_card_modal: {
      state: false,
    },
    mobile_nav_modal: {
      state: false,
    },
  }),
  getters: {},
  actions: {
    errorHandle(error) {
      const reason = error.message
        .split("reason:")[1]
        ?.split("Contract Call:")[0]
        ?.trim();

      if (reason == "") {
        return "An unknown error has occurred.";
      } else {
        return reason;
      }
    },
    showLoadingModal() {
      this.loading_modal.state = true;
    },
    hideLoadingModal() {
      this.loading_modal.state = false;
    },
    showAlertModal(title, message) {
      this.alert_modal.state = true;
      this.alert_modal.title = title;
      this.alert_modal.message = message;
    },
    hideAlertModal() {
      this.alert_modal.state = false;
    },
    showMintingInfoModal(img, name, description) {
      this.minting_info_modal.state = true;
      this.minting_info_modal.img = img;
      this.minting_info_modal.name = name;
      this.minting_info_modal.description = description;
    },
    hideMintingInfoModal() {
      this.minting_info_modal.state = false;
    },
    showNftCardModal() {
      this.nft_card_modal.state = true;
    },
    hideNftCardModal() {
      this.nft_card_modal.state = false;
    },
    showMobileNavModal() {
      this.mobile_nav_modal.state = true;
    },
    hideMobileNavModal() {
      this.mobile_nav_modal.state = false;
    },
  },
});
